import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ContentContainer,
  PageHeading,
  StyledDate,
  StyledLink,
} from "../components/shared-styles"
import colors from "../utils/colors"
import formatDate from "../utils/formatDate"

const StyledHeading = styled.h3`
  &:hover {
    color: ${colors.blue};
  }
`

const Post = styled.section`
  margin-bottom: 3rem;
`

const Blog = ({ data }) => {
  const blogPosts = data.allContentfulBlogPost.edges
  return (
    <Layout>
      <ContentContainer>
        <SEO title="Blog posts" />
        <PageHeading>{"Blog"}</PageHeading>
        <div className="blogposts">
          {blogPosts.map(({ node: post }) => (
            <Post key={post.id}>
              <div>
                <StyledDate>{formatDate(post.createdAt)}</StyledDate>
                <Link to={`/post/${post.slug}`}>
                  <StyledHeading style={{ marginTop: 0 }}>
                    {post.title}
                  </StyledHeading>
                </Link>
                <p>
                  {
                    post.childContentfulBlogPostBodyTextNode.childMarkdownRemark
                      .excerpt
                  }
                  <br />
                  <StyledLink to={`/post/${post.slug}`}>Read More →</StyledLink>
                </p>
              </div>
            </Post>
          ))}
        </div>
      </ContentContainer>
    </Layout>
  )
}
export default Blog

export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulBlogPost(
      limit: 1000
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          childContentfulBlogPostBodyTextNode {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 150)
            }
          }
          image {
            file {
              url
            }
          }
          tags
          createdAt
        }
      }
    }
  }
`
